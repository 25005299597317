<template>
  <div class="tableList">
     <global-table  :tableField="tableField"  :tableData="dialogdata"></global-table>
  </div>
</template>

<script>
import GlobalTable from '@/components/actionList/GlobalTable.vue'
import { tableField, tableData } from './js/PrepaidSterilisationdialog'
export default {
  props: ['dialogdata'],
  name: 'PrepaidSterilisationdialog',
  components: { GlobalTable },
  data () {
    return {
      tableField: tableField,
      tableData: tableData
    }
  },
  mounted () {
  },
  methods: {
  }

}
</script>
<style scoped>
.tableList{
padding: 0px;
}
</style>
