
export var tableField = [
  { selectType: 'selection', width: '', type: 'input' },
  { label: '序号', code: 'serialNumber', type: 'function', width: '50px', handle (index) { return index + 1 } },
  { label: '预付申请号', code: 'payCode', type: 'input', width: '100px' },
  { label: '采购单编号', code: 'orderCode', type: 'input', width: '' },
  { label: '采购单行号', code: 'rowProject', type: 'input', width: '' },
  { label: '预付金额', code: 'prepayMoney', type: 'input', width: '' }
]
export var tableData = []
